/* GourmaiModal.css */
.custom-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: #000; /* Couleur par défaut */
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s, color 0.2s; /* Ajout de la transition pour le changement de couleur */
    position: absolute;
    top: 10px;
    right: 15px;
}

.custom-close:hover {
    opacity: 1; /* Opacité complète au survol */
    color: #000; /* Couleur personnalisée au survol */
    background-color: #ec7842;
}


.modal-header {
    border-bottom: none; /* Supprime la ligne séparatrice */
    position: relative; /* Pour positionner la croix personnalisée */
}

.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .pdf-container {
        width: 100%;
    }
}

.react-pdf__Page {
    margin: 0 auto;
    box-shadow: none;
}
