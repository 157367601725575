/* GourmaiModal.css */
.modal-content-container {
    text-align: center;
    font-family: 'Lato', sans-serif;
    padding: 20px;
}

h2 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    color: #333;
}

.description {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
    color: #444;
}

.techno {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    color: #666;
}

.images-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-pair {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Permet de passer à la ligne sur mobile */
}

.modal-image {
    width: 45%;
    max-width: 400px;
    margin: 20px 20px 0 20px; /* Ajoute du margin-top */
    border-radius: 8px;
}

.separator-horizontal {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ccc;
    transform: translateY(-50%);
}

.separator-vertical {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #ccc;
    transform: translateX(-50%);
}

.description {
    margin-top: 30px;
}
a {
    color: #ec7842;
}
a:hover {
    color: #ec7842; 
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.icon-link {
    margin: 0 5px;
}

.icon-image {
    width: 50px; 
    height: 50px; 
    margin-bottom: 5px;
}


/* Media Queries */
@media (max-width: 768px) {
    .modal-image {
        width: 90%; /* Images prennent plus d'espace sur mobile */
        margin: 10px 0; /* Moins de marge autour des images */
    }

    .separator-horizontal, .separator-vertical {
        display: none; /* Masquer les séparateurs sur mobile */
    }

    .image-pair {
        flex-direction: column; /* Disposition en colonne sur mobile */
    }
}
