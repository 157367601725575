.paper-background {
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 40px auto;
}

.cv-container {
    font-family: 'Lato', sans-serif;
}

.section-title {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 1.5rem;
}

.section-title::after {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background-color: #ec7842;
    position: absolute;
    bottom: -5px;
}

.section-separator {
    content: "";
    display: block;
    width: 80%;
    height: 1px;
    background-color: #ddd;
    margin: 20px auto; /* Center the separator with top and bottom margins */
}

.mobile-only {
    display: none; /* Hide by default */
}

.desktop-border-left {
    border-left: none; /* Ensure no border by default */
}

#aixellium-link {
    color: #ec7842;
}

.list-group-item {
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    background-color: #f8f9fa;
}

.portfolio-header {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 1.2rem;
    margin: 20px 0;
}

.icon {
    width: 24px;
    height: 24px;
    margin: 0 5px;
    vertical-align: middle;
}

/* Mobile Specific Styles */
@media (max-width: 768px) {
    .cv-container {
        padding: 0 15px; /* Padding added for mobile */
    }

    .section {
        margin-bottom: 30px; /* Increase space between sections */
    }

    .section-separator {
        display: block; /* Ensure the main separator is visible */
    }

    .mobile-only {
        display: block; /* Show on mobile */
    }
}

/* Desktop Specific Styles */
@media (min-width: 769px) {
    .desktop-border-left {
        border-left: 1px solid #ddd; /* Add left border on desktop */
        padding-left: 15px;
    }
}
