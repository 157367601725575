/* Technologies.css */
.technologies-container {
    margin: 0 auto;
}

.title {
    font-size: 2.5rem; /* Augmente la taille de la police */
    font-weight: 700; /* Rend le texte plus gras */
    font-family: 'Lato', sans-serif; /* Utilise la police Lato */
    color: #ec7842; /* Ajuste la couleur selon votre préférence */
    margin-top: 50px; /* Espace en haut du titre */
    margin-bottom: 50px; /* Espace en bas du titre */
    text-align: center; /* Centre le texte */
}

.tech-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ajoute une ombre */
    max-width: 80%;
    cursor: default; /* Désactiver le curseur */
    transform: none !important; /* Désactiver les transformations */
    margin: 0 auto; /* Centrer la carte */
    transform:none;
}

.card-title {
    font-weight: bold; /* Rend le titre de la carte plus gras */
    font-size: 1.2rem; /* Ajuste la taille de la police */
}

.card-img-top {
    display: block; /* Centre l'image horizontalement */
    margin: 0 auto; /* Centre l'image horizontalement */
    object-fit: contain; /* Conserve les proportions de l'image */
    width: auto; /* Ajuste la largeur en fonction du ratio de l'image */
    height: 200px; /* Ajuste la hauteur en fonction de la taille de l'image */
    max-width: 100%; /* Empêche l'image de déborder */
}
