/* AixelliumModal.css */
.custom-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s, color 0.2s;
    position: absolute;
    top: 10px;
    right: 15px;
}

.custom-close:hover {
    opacity: 1;
    color: #ec7842;
}

.modal-header {
    border-bottom: none;
    position: relative;
}

.modal-content-container {
    text-align: center;
    font-family: 'Lato', sans-serif;
    padding: 20px;
}

h2 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    color: #333;
}

.description {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
    color: #444;
}

.techno {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    color: #666;
}

.mission-description {
    margin-bottom: 20px;
    font-size: 18px;
    color: #555;
}

.images-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-pair {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Permet de passer à la ligne sur mobile */
}

.modal-image {
    width: 45%;
    max-width: 400px;
    margin: 20px 20px 0 20px; /* Ajoute du margin-top */
    border-radius: 8px;
}

.separator-horizontal {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ccc;
    transform: translateY(-50%);
}

.separator-vertical {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #ccc;
    transform: translateX(-50%);
}

.description {
    margin-top: 30px;
}

/* Media Queries */
@media (max-width: 768px) {
    .modal-image {
        width: 90%; /* Images prennent plus d'espace sur mobile */
        margin: 10px 0; /* Moins de marge autour des images */
    }

    .separator-horizontal, .separator-vertical {
        display: none; /* Masquer les séparateurs sur mobile */
    }

    .image-pair {
        flex-direction: column; /* Disposition en colonne sur mobile */
    }
}
