/* Navbar.css */
.navbar {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 10;
}

.menu-icon {
    display: none; /* Cacher le menu hamburger par défaut sur les écrans non mobiles */
    cursor: pointer;
}

.menu-icon-bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
    transition: 0.4s;
}

.menu-icon.open .menu-icon-bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.open .menu-icon-bar:nth-child(2) {
    opacity: 0;
}

.menu-icon.open .menu-icon-bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li {
    margin-left: 20px;
    position: relative;
}

.menu a {
    text-decoration: none;
    color: black; /* Maintain original color */
    font-size: 1.2rem;
    padding: 4px 8px;
    position: relative;
    overflow: hidden;
}

.menu a:before {
    content: '';
    position: absolute;
    width: 0; /* Start with zero width */
    height: 2px; /* Thickness of the underline */
    bottom: 0;
    left: 0; /* Always start from the left edge */
    background-color: #ec7842; /* Blue underline */
    transition: width 0.3s; /* Smooth animation for expanding width */
}

.menu .active a:before {
    width: 100%; /* Expand to full width */
}
.menu a#home-link {
    color: black; /* Couleur du texte pour le lien "Accueil" */
}

/* Navbar.css */


#home-link.active a:before {
    background-color: black; /* Change the underline color for the active link */
}
.menu.open {
    display: none; /* Cacher le menu sur les écrans non mobiles */
}

@media screen and (max-width: 768px) {
    .menu-icon {
        display: block; /* Afficher le menu hamburger sur les écrans mobiles */
    }

    .navbar ul {
        display: none; /* Cacher la liste de liens sur les écrans mobiles par défaut */
    }

    .menu.open {
        display: flex; /* Afficher le menu hamburger lorsqu'il est ouvert */
        flex-direction: column;
        background-color: #f9f9f9; /* Fond du menu hamburger */
        position: absolute;
        top: 40px;
        right: 0;
        width: 150px;
    }
}
