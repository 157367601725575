/* Projets.css */

.projets-container {
    margin: 0 auto;
}

.title {
    font-size: 2.8rem; /* Increase the font size */
    font-weight: 700; /* Make it bolder */
    font-family: 'Lato', sans-serif; /* Ensure Lato font is used */
    margin-top: 50px; /* Add more space above the title */
    margin-bottom: 50px; /* Add more space below the title */
    text-align: center; /* Center the title text */
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for a lifted effect */
    border-radius: 10px; /* Rounded corners */
    transition: transform 0.2s; /* Smooth scaling effect on hover */
    cursor: pointer;
}

.card-title {
    font-weight: bold; /* Make the card title bold */
    font-size: 1.2rem; /* Adjust the font size */
}

.card:hover {
    transform: scale(1.05); /* Scale up on hover */
}

.card-img-top {
    display: block; /* Center the image horizontally */
    margin: 0 auto; /* Center the image horizontally */
    object-fit: contain; /* Ensure the image maintains its proportions */
    width: auto; /* Adjust the width according to the image aspect ratio */
    height: 200px; /* Adjust the height based on the desired image size */
    max-width: 100%; /* Prevent image from overflowing */
}
