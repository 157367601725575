html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Lato', sans-serif;
}

.home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
    background-color: black; /* Black background behind the video */
    color: black;
    overflow: hidden;
    font-family: 'Lato', sans-serif;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.main-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3rem;
    z-index: 1;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    padding-bottom: 0.5rem;
}

.animated-text {
    font-size: 4rem;
    font-weight: bold;
    color: black;
    z-index: 1;
}

/* Separator Style */
.separator {
    width: 30%;
    border-top: 2px solid rgba(0, 0, 0, 0.5);
    margin: 1rem 0;
}

/* Material UI Scroll arrow */
.scroll-arrow {
    font-size: 3rem; /* Adjust size */
    cursor: pointer; /* Hand cursor */
    margin-top: 2rem; /* Adjust spacing */
    z-index: 2; /* On top of other elements */
    animation: bounce 2s infinite; /* Smooth bounce animation */
}

/* Bounce animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

@media (max-width: 768px) {
    .main-title {
        font-size: 1.5rem;
        border-bottom-width: 1px;
    }
    .animated-text {
        font-size: 2.5rem;
    }
    .scroll-arrow {
        font-size: 2rem;
    }
}
