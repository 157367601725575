/* TrinPartnerModal.css */
.custom-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s, color 0.2s;
    position: absolute;
    top: 10px;
    right: 15px;
}

.custom-close:hover {
    opacity: 1;
    color: #ec7842;
}

.modal-header {
    border-bottom: none;
    position: relative;
}

.modal-content-container {
    text-align: center;
    padding: 20px;
}

h2 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    color: #333;
}

.description {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
    color: #444;
}

.techno {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    color: #666;
}

.mission-description {
    margin-bottom: 20px;
    font-size: 18px;
    color: #555;
}

.images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-single {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.image-single img {
    width: 60%; /* Augmente la taille de l'image */
    max-width: 600px; /* Assure que l'image ne soit pas trop grande */
    border-radius: 8px;
}

.image-pair {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.modal-image {
    width: 45%;
    max-width: 400px;
    margin: 0 10px;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .mission-description {
        font-size: 16px;
    }

    .modal-image {
        width: 90%;
        margin: 10px 0;
    }

    .image-pair {
        flex-direction: column;
    }

    .image-single img {
        width: 80%; /* Ajuste la taille sur mobile pour être plus grand */
    }
}
